/* istanbul ignore file */
import KatalLogger, { Level } from "@amzn/katal-logger";

const katalLoggerConfig = {
  url: "https://9uqchsspwh.execute-api.ap-south-1.amazonaws.com/prod/v1/log",
  logThreshold: Level.INFO,
  maxLogLineSize: 10000,
  logToConsole: true,
};

export const logger = new KatalLogger(katalLoggerConfig);
logger.addErrorListener();
