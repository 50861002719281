export const LOCAL = "http://0.0.0.0";
export const BETA = "https://d1dnzrldbrd3a7.cloudfront.net";
export const PROD = "https://df8hefps1tk0s.cloudfront.net";
export const STAGES = ["beta", "prod"];

export const getStage = (hostName: string) => {
  const root = hostName.split(".")[0].split("//")[1];
  if (hostName.includes(LOCAL)) {
    return "local";
  } else if (hostName.includes(PROD)) {
    return "prod";
  } else if (STAGES.includes(root)) {
    return root;
  }
  return "beta";
};

const stageConfigurationDetails = {
  local: {
    AppWebDomain: "upaj-web-auth-beta.auth.ap-south-1.amazoncognito.com", // The domain name in Cognito "App integration > Domain name", without https://
    ClientId: "8e9tk4pcmljvih2io9t24naec", // the client ID from Cognito "General settings > App clients" page
    RedirectUriSignIn: "http://0.0.0.0:4321/", // exactly same as the callbacks URLS in Cognito "App integration > App client settings" page
    RedirectUriSignOut: "http://0.0.0.0:4321/", // exactly same as the sign out URLS in Cognito "App integration > App client settings" page
    TokenScopesArray: ["openid"],
    UserPoolId: "ap-south-1_6kx2N7njX", // the user pool from Cognito "General settings" page
    region: "ap-south-1",
    userPoolId: "ap-south-1_6kx2N7njX",
    providerName: "cognito-idp.ap-south-1.amazonaws.com/",
    identityPoolId: "ap-south-1:34f6fe22-94b7-40a4-a4cd-3577e42420f3",
    invokeUrlURL:
      "https://mg8l0rqg38.execute-api.ap-south-1.amazonaws.com/beta", // API getway invoke url
  },
  beta: {
    AppWebDomain: "upaj-web-auth-beta.auth.ap-south-1.amazoncognito.com", // The domain name in Cognito "App integration > Domain name", without https://
    ClientId: "8e9tk4pcmljvih2io9t24naec", // the client ID from Cognito "General settings > App clients" page
    RedirectUriSignIn: "https://beta.kisan-engagement.kisan.amazon.dev/", // exactly same as the callbacks URLS in Cognito "App integration > App client settings" page
    RedirectUriSignOut: "https://beta.kisan-engagement.kisan.amazon.dev/", // exactly same as the sign out URLS in Cognito "App integration > App client settings" page
    TokenScopesArray: ["openid"],
    UserPoolId: "ap-south-1_6kx2N7njX", // the user pool from Cognito "General settings" page
    region: "ap-south-1",
    userPoolId: "ap-south-1_6kx2N7njX",
    providerName: "cognito-idp.ap-south-1.amazonaws.com/",
    identityPoolId: "ap-south-1:34f6fe22-94b7-40a4-a4cd-3577e42420f3",
    invokeUrlURL:
      "https://mg8l0rqg38.execute-api.ap-south-1.amazonaws.com/beta", // API getway invoke url
  },
  prod: {
    AppWebDomain: "upaj-web-auth-prod.auth.ap-south-1.amazoncognito.com", // The domain name in Cognito "App integration > Domain name", without https://
    ClientId: "3v0dkav0ltpihj7rfm0aoioc90", // the client ID from Cognito "General settings > App clients" page
    RedirectUriSignIn: "https://prod.kisan-engagement.kisan.amazon.dev/", // exactly same as the callbacks URLS in Cognito "App integration > App client settings" page
    RedirectUriSignOut: "https://prod.kisan-engagement.kisan.amazon.dev/", // exactly same as the sign out URLS in Cognito "App integration > App client settings" page
    TokenScopesArray: ["openid"],
    UserPoolId: "ap-south-1_VIY3fNDpn", // the user pool from Cognito "General settings" page
    region: "ap-south-1",
    userPoolId: "ap-south-1_VIY3fNDpn",
    providerName: "cognito-idp.ap-south-1.amazonaws.com/",
    identityPoolId: "ap-south-1:b2ac5dd8-1b66-4efb-9825-e1fb449ffad5",
    invokeUrlURL:
      "https://jf2jke9kx2.execute-api.ap-south-1.amazonaws.com/prod", // API getway invoke url
  },
};

export const getCognitoConfigDetails =
  stageConfigurationDetails[
    getStage(
      `${window.location.protocol}//${window.location.hostname}`
    ) as keyof typeof stageConfigurationDetails
  ];
