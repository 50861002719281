import { html, property } from 'lit-element';
import { nothing } from 'lit-html';
import { ifNotNull } from '../../utils/directives';
import { KatLitElement, register, nextUniqueId } from '../../shared/base';
import { formInputMap } from '../../utils/form-input-map';
import baseStyles from '../../shared/base/base.lit.scss';
import formItemStyles from '../../shared/base/form-item.base.lit.scss';
import styles from './input.lit.scss';

/**
 * @component {kat-input} KatalInput An input allows users to input a short amount of free-form text.
 * @guideline Do All input fields should have a label.
 * @guideline Do Allow for extra space for extra characters when localized into other languages.
 * @guideline Do Include brief hint (placeholder) text inside an input field if you need to indicate what the customer should type into the field, however leave the field blank if the label is self-explanatory.
 * @event blur This event fires whenever the input is unselected.
 * @event input This event fires whenever the user types a single character.
 * @event change This event fires whenever the user commits their change.
 * @event focus This event fires whenever the input is selected.
 * @event keydown This event fires whenever the keyboard starts entering a character.
 * @event keyup This event fires whenever the keyboard is finished entering a character.
 * @event mouseup This event fires whenever the mouse button is released.
 * @status Production
 * @theme flo
 * @example Basic {"value": "John Doe", "label":"Name", "type":"text"}
 * @example WithConstraint {"value": "John Doe", "label":"Name", "type":"text","constraint-emphasis":"Attention:", "constraint-label":"This is your first and last name"}
 * @example ErrorState {"value": "", "label":"Name", "type":"text","state-emphasis":"Error:", "state-label":"your name is required", "state": "error"}
 * @example Disabled {"value": "John Doe", "label":"Name", "type":"text", "disabled":"true"}
 * @example WithTooltip {"label": "Input with tooltip", "value": "", "tooltip-text": "Hello world"}
 * @a11y {keyboard}
 * @a11y {sr}
 * @a11y {contrast}
 */
@register('kat-input')
export class KatInput extends KatLitElement {
  /** The input provided by the user that can be edited */
  @property({ reflect: false })
  get value(): string {
    if (this._input) {
      return this._input.value;
    }
    return this._value;
  }

  set value(val: string) {
    const oldVal = this.value;
    this._value = val;
    if (this._input) {
      // keep this updated so that when lit checks if it needs to re-render, it
      // sees the new value
      this._input.value = val;
    }
    this.requestUpdate('value', oldVal);
  }

  /**
   * The type of input to display to a user.
   * @enum {value} text Collects basic text input from a user.  This is the default option
   * @enum {value} password Allows a user to enter a password
   * @enum {value} number Allows a user to enter in a number only
   * @enum {value} email defines a field for an e-mail address
   * @enum {value} search defines a text field for entering a search string
   * @enum {value} url defines a field for entering a URL.
   */
  @property()
  type = 'text';

  /** Label that describes what the input represents */
  @property()
  label?: string;

  /** Used to associate an external label to an input.  See the kat-label for attribute. */
  @property({ attribute: 'unique-id' })
  uniqueId: string = nextUniqueId();

  /** Provides a example of what the user is suppose to enter */
  @property()
  placeholder?: string;

  /** Optional pretext for the constraint label.  Used to provide additional context to the constraint for the input */
  @property({ attribute: 'constraint-emphasis' })
  constraintEmphasis?: string;

  /** Provides users with more information about what they enter into the input */
  @property({ attribute: 'constraint-label' })
  constraintLabel?: string;

  /**
   * Setting the state of the input changes its look to give the user more information about what they have entered.  This value must be set for State Labels to show up.
   * @enum {value} error Lets the user know there is a problem with the value they have entered in the input
   */
  @property()
  state?: string;

  /** Optional pretext for the state label.  Used to provide additional context to the state for the input */
  @property({ attribute: 'state-emphasis' })
  stateEmphasis?: string;

  /** Provides users with more information about why the input is in the state its in */
  @property({ attribute: 'state-label' })
  stateLabel?: string;

  /** Specifies the intervals numbers can be entered */
  @property()
  step?: string;

  /** Prevents the user from changing the value of an input, prevents events from being triggered, prevents value collection in a form */
  @property()
  disabled?: boolean;

  /** Specifies the value of the input when it is sent to the server in a form submission */
  @property()
  name?: string;

  /** Specifies a regular expression that the value is checked against */
  @property()
  pattern?: string;

  /** Specifies that the element is read only */
  @property()
  readOnly?: boolean;

  /**
   * Specifies that the element should receive focus by default
   * @required false
   */
  @property()
  autofocus: boolean;

  /** Adds a red border around the input area if the user clicks away from that input area without entering any text. This is meant as a visual cue and does not automatically prevent the form from being submitted. */
  @property()
  required?: boolean;

  /**
   * Specifies the maxlength
   * @type {string | number}
   */
  @property()
  maxLength?: number;

  /**
   * Specifies a minimum value
   * @type {string | number}
   */
  @property()
  min?: number;

  /**
   * Specifies a max value
   * @type {string | number}
   */
  @property()
  max?: number;

  /**
   * Specifies the autocomplete behaviour that a browser should attempt:
   * https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
   */
  @property()
  autocomplete?: string;

  /** The label used exclusively by screenreaders as a fallback if the regular label is not specified. */
  @property({ attribute: 'kat-aria-label' })
  katAriaLabel?: string;

  /**
   * Controls whether element may be checked for spelling errors. Defaults to false.
   * See https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/spellcheck#security_and_privacy_concerns before enabling.
   */
  @property({ attribute: 'kat-spellcheck' })
  katSpellcheck = false;

  /**
   * The text to be shown inside the tooltip placed next to the label text. The tooltip will only appear if this is
   * set.
   */
  @property({ attribute: 'tooltip-text' })
  tooltipText?: string;

  /** The position of the tooltip. Defaults to "top". */
  @property({ attribute: 'tooltip-position' })
  tooltipPosition?: string;

  /** The icon that triggers the tooltip next to the label. Defaults to "help_outline". */
  @property({ attribute: 'tooltip-trigger-icon' })
  tooltipTriggerIcon?: string;

  static get styles() {
    return [baseStyles, formItemStyles, styles];
  }

  _value = '';
  _input: HTMLDataElement;

  firstUpdated() {
    super.firstUpdated();
    this._input = this._shadow('input') as HTMLDataElement;
  }

  @formInputMap([
    {
      tag: 'input',
      name: (component: KatInput) => component.name,
      isNeeded: (component: KatInput) => component.isFormInputNeeded(),
      setup: (component: KatInput, input: HTMLInputElement) =>
        component.setupFormInput(input),
    },
  ])
  updated(changedProps) {
    super.updated(changedProps);
  }

  isFormInputNeeded() {
    return !(this.disabled || !this.name);
  }

  isSpellCheckFalse() {
    return this.spellcheck === false || this.katSpellcheck === false;
  }

  setupFormInput(input: HTMLInputElement) {
    input.value = this.value;
    input.setAttribute('type', this.type);

    if (this.isSpellCheckFalse()) {
      input.setAttribute('spellcheck', `false`);
    }
    this.readOnly && input.setAttribute('readonly', 'readonly');
    this.required && input.setAttribute('required', 'required');
    this.pattern && input.setAttribute('pattern', this.pattern);
  }

  // change is the only native event fired by input that isn't composed by
  // default, so we have to re-emit it
  _handleChange(e) {
    // Make sure we update LightDOM
    const changes = new Map();
    changes.set('value', e.target.value);
    this.updated(changes);

    const event = new Event('change');
    this.dispatchEvent(event);
  }

  _handleBlur() {
    if (!this.classList.contains('touched')) {
      this.classList.add('touched');
    }
  }

  focus() {
    this._input.focus();
  }

  render() {
    const label = this.label
      ? html`
          <kat-label
            class="form-label"
            part="input-label"
            for=${this.uniqueId}
            text=${this.label}
            .tooltipText=${ifNotNull(this.tooltipText)}
            .tooltipPosition=${ifNotNull(this.tooltipPosition)}
            .tooltipTriggerIcon=${ifNotNull(this.tooltipTriggerIcon)}
          ></kat-label>
        `
      : nothing;

    const constraintLabel =
      this.constraintLabel || this.constraintEmphasis
        ? html`
            <kat-label
              class="constraint-label"
              part="input-constraint-label"
              for=${this.uniqueId}
              variant="constraint"
              emphasis=${ifNotNull(this.constraintEmphasis)}
              text=${ifNotNull(this.constraintLabel)}
            ></kat-label>
          `
        : nothing;

    const stateLabel =
      (this.stateLabel || this.stateEmphasis) && this.state
        ? html`
            <kat-label
              class="state-label"
              part="input-state-label"
              variant="constraint"
              text=${ifNotNull(this.stateLabel)}
              emphasis=${ifNotNull(this.stateEmphasis)}
              state=${ifNotNull(this.state)}
            ></kat-label>
          `
        : nothing;

    return html`
      ${label}
      <span class="container">
        <input
          id=${this.uniqueId}
          class=${ifNotNull(this.state)}
          part="input"
          type=${this.type}
          placeholder=${ifNotNull(this.placeholder)}
          name=${ifNotNull(this.name)}
          step=${ifNotNull(this.step)}
          maxlength=${ifNotNull(this.maxLength)}
          pattern=${ifNotNull(this.pattern)}
          min=${ifNotNull(this.min)}
          max=${ifNotNull(this.max)}
          autocomplete=${ifNotNull(this.autocomplete)}
          aria-label=${ifNotNull(this.katAriaLabel)}
          .value=${this.value || ''}
          spellcheck=${this.katSpellcheck}
          ?disabled=${this.disabled}
          ?readonly=${this.readOnly}
          ?autofocus=${this.autofocus}
          ?required=${this.required}
          @change=${this._handleChange}
          @blur=${this._handleBlur}
          @search=${this._handleSearch}
        />
        <span class="ring"></span>
      </span>
      <div class="metadata">${constraintLabel} ${stateLabel}</div>
    `;
  }

  _handleSearch() {
    const event = new Event('search');
    this.dispatchEvent(event);
  }
}
